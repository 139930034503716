import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import '../styles/Training.css';
import TrainingSettings from './training_subpages/TrainingSettings';
import DeviceSetup from './training_subpages/DeviceSetup';
import Countdown from './training_subpages/Countdown';
import Recording from './training_subpages/Recording';
import ProgressWheel from './training_subpages/ProgressWheel';
import EvaluationResult from './training_subpages/Evaluate';
import config from '../config/config';
import WebRTCManager from '../utils/WebRTCManager';

const Training = () => {
    const [phase, setPhase] = useState('settings'); // Current phase of the training
    const [count, setCount] = useState(4); // Countdown timer state
    const [formData, setFormData] = useState(null); // Form data submitted by the user
    const [mediaStream, setMediaStream] = useState(null); // Media stream for audio/video
    const [evalData, setEvalData] = useState(null); // Evaluation data from the server
    const [connectionStatus, setConnectionStatus] = useState('connecting'); // Default to 'connecting'

    const navigate = useNavigate();

    const handleMessage = (event) => {
        if (event.data === 'backend_stop') {
            webRTCManagerRef.current.peerConnection.close();
        }
        if (event.data === 'backend_error') {
            webRTCManagerRef.current.peerConnection.close();
            console.error('Backend error - connection');
            setPhase('error');
        }
    };

    // WebRTC Manager
    const webRTCManagerRef = useRef(null);
    const sessionIdRef = useRef(null);

    useEffect(() => {
        if (!webRTCManagerRef.current) {
            webRTCManagerRef.current = new WebRTCManager(config, handleMessage);
        }
    }, []);

    // Phase-dependent logic
    useEffect(() => {
        if (phase === 'settings') {
            webRTCManagerRef.current.createSDPOffer()
                .then((offerSuccess) => {
                    if (!offerSuccess) setPhase('error');
                })
                .catch((error) => {
                    console.error("Error creating SDP offer:", error);
                    setPhase('error'); // Handle rejection as an error state
                });
        }

        if (phase === 'waiting_for_result') {
            pollStatus().then((result) => {
                if (result?.status === 'finished') {
                    fetchEvaluationData().then((data) => {
                        setEvalData(data);
                        if (data) {
                            setPhase('evaluation');
                        }
                    });
                } else {
                    setPhase('error');
                }
            });
        }
    }, [phase, mediaStream]);



    // Countdown handling
    useEffect(() => {
        if (phase === 'countdown' && count > 0) {
            const timer = setTimeout(() => setCount((prev) => prev - 1), 1000);
            return () => clearTimeout(timer); // Cleanup timer on phase change
        } else if (phase === 'countdown' && count === 0) {
            handleStartRecording(); // Transition to recording phase
        }
    }, [count, phase]);

    const pollStatus = async () => {
        let isFinished = false;
        const maxAttempts = 10;
        let attempts = 0;
        let statusResult = null;

        while (!isFinished && attempts < maxAttempts) {
            try {

                statusResult = await getStatus();
                if (statusResult?.status === 'finished') {
                    isFinished = true;
                } else {
                    attempts++;
                    await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait 1 second
                }
            } catch (error) {
                console.error("Error polling status:", error);
                break;
            }
        }

        if (!isFinished && attempts >= maxAttempts) {
            console.error("Status did not finish within the allocated time.");
            setPhase('error');
        }

        return statusResult;
    };

    const getStatus = async () => {

        const sessionId = await sessionIdRef.current;
        try {
            const response = await fetch(`${config.API_URL}/status_processing`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ session_id: sessionId }),
            });

            if (!response.ok) {
                console.error('Failed to fetch status:', response.status);
                return null;
            }

            const statusResult = await response.json();
            return statusResult;
        } catch (error) {
            console.error('Error polling status:', error);
            return null;
        }
    };

    const fetchEvaluationData = async () => {
        const sessionId = await sessionIdRef.current;
        try {
            const response = await fetch(`${config.API_URL}/evaluation`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ session_id: sessionId }),
            });

            if (!response.ok) {
                console.error('Failed to fetch evaluation data:', response.status);
                setPhase('error');
                return null;
            }

            const data = await response.json();
            setEvalData(data);
            return data;
        } catch (error) {
            console.error('Error fetching evaluation data:', error);
            setPhase('error');
            return null;
        }
    };

    const handleExerciseNow = (data) => {
        setFormData(data);
        setPhase('device_setup'); // Proceed immediately to the next phase
    
        (async () => {
            try {
                const sessionId = await webRTCManagerRef.current.sendSDPOffer(data);
                sessionIdRef.current = sessionId;
    
                if (!sessionId) {
                    console.error('Failed to create session');
                    setPhase('error');
                } else {
                    try {
                        await webRTCManagerRef.current.trackConnection(); // Wait for the connection state to reach 'connected'
                        setConnectionStatus('connected');
                    } catch (error) {
                        console.error('Connection tracking failed:', error.message);
                        setConnectionStatus('timeout');
                        setPhase('error');
                    }
                }
            } catch (error) {
                console.error('Error during session creation or connection tracking:', error);
                setPhase('error');
            }
        })();
    };
    
    

    const handleStart = () => {
        if (mediaStream) {
            mediaStream.getAudioTracks().forEach((audioTrack) =>
                webRTCManagerRef.current.replaceTrack('audio', audioTrack)
            );
            // mediaStream.getVideoTracks().forEach((videoTrack) =>
            //     webRTCManagerRef.current.replaceTrack('video', videoTrack)
            // );
        }
        setPhase('countdown');
        setCount(4);
    };

    const handleStartRecording = () => {
        setPhase('recording');
        webRTCManagerRef.current.sendMessage('start');
    };

    const handleStopRecording = () => {
        webRTCManagerRef.current.sendMessage('stop');
        if (mediaStream) {
            mediaStream.getTracks().forEach((track) => track.stop());
        }
        setMediaStream(null);
        navigate('/welcome');
    };

    const handleBack = () => {
        if (phase === 'device_setup') {
            if (mediaStream) {
                mediaStream.getTracks().forEach((track) => track.stop());
                setMediaStream(null);
            }
            setPhase('settings');
        } else if (['settings', 'evaluation', 'error'].includes(phase)) {
            navigate('/welcome');
        }
    };

    const handleEvaluate = () => {
        if (phase === 'recording') {
            webRTCManagerRef.current.sendMessage('stop');
            if (mediaStream) {
                mediaStream.getTracks().forEach((track) => track.stop());
                setMediaStream(null);
            }
            setPhase('waiting_for_result');
        }
    };

    return (
        <div className="training-page">
            <h1>Training</h1>
            {phase === 'settings' && (
                <TrainingSettings onExerciseNow={handleExerciseNow} handleBack={handleBack} />
            )}
            {phase === 'device_setup' && (
                <DeviceSetup onStart={handleStart}
                    onBack={handleBack}
                    setMediaStream={setMediaStream}
                    connectionStatus={connectionStatus}
                />
            )}
            {phase === 'countdown' && (
                <Countdown count={count} />
            )}
            {phase === 'recording' && (
                <Recording
                    formData={formData}
                    mediaStream={mediaStream}
                    onStop={handleStopRecording}
                    onEvaluate={handleEvaluate}
                />
            )}
            {phase === 'waiting_for_result' && (
                <ProgressWheel />
            )}
            {phase === 'evaluation' && (
                <EvaluationResult onHome={handleBack} data={evalData} trainingSettings={formData} />
            )}
            {phase === 'error' && (
                <div>
                    <h2>There was an error processing the evaluation.</h2>
                    <button onClick={handleBack}>Zurück</button>
                </div>
            )}
        </div>
    );
};

export default Training;
