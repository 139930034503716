import React, { useState } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import '../styles/Sidebar.css';

import config from '../config/config';

import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { useAuth } from '../contexts/AuthContext'; // Import AuthContext



const PresadaSidebar = () => {
  const [collapsed, setCollapsed] = useState(false);

  const navigate = useNavigate();
  const { logout, isAdmin } = useAuth();

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = async () => {

    try {
      const response = await fetch(`${config.AUTH_URL}/logout`, {
        method: 'POST',
        credentials: 'include', // Include cookies with the request
      });
  
      if (response.ok) {
        logout();
        navigate('/login');
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('An error occurred during logout:', error);
    }

   
  };

  const menuItemStyles = {
    root: {
      fontSize: '16px',
      color: 'var(--black)',
      backgroundColor: 'var(--white)',
      padding: '10px',
      border: 'none',
    },
    icon: {
      color: 'var(--turquoise)',
    },
    label: {
      fontWeight: 'bold',
    },
    button: {
      '&:hover':
      {
        backgroundColor: 'var(--light-gray)',
      }
    }
  };


  return (
    <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
      <button onClick={handleToggle} className="toggle-button">
        <MenuIcon style={{ '&hover': { color: 'var(--white)' } }} />
      </button>
      <Sidebar collapsed={collapsed} style={{ border: 'none', boxShadow: 'none' }}>
        <Menu menuItemStyles={menuItemStyles}>
          <MenuItem icon={<HomeIcon />} onClick={() => navigate('/welcome')}>Home</MenuItem>
          <MenuItem icon={<AssignmentIcon />} onClick={() => navigate('/training')}>Training</MenuItem>
          {isAdmin && (
            <MenuItem icon={<SettingsIcon />} onClick={() => navigate('/settings')}>Settings</MenuItem>
          )}
          <MenuItem icon={<ExitToAppIcon style={{color: 'var(--red)'}}/>} onClick={() => handleLogout()} style={{ borderTop: '3px solid var(--light-gray)', marginTop: '10px' }}> <span style={{ color: 'var(--red)' }}>LogOut</span></MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default PresadaSidebar;
