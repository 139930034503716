import React, { useState, useEffect, useRef } from 'react';
import VideocamOff from '@mui/icons-material/VideocamOff';
import MicOffIcon from '@mui/icons-material/MicOff';

import '../../styles/Training.css';

const DeviceSetup = ({ onStart, onBack, setMediaStream, connectionStatus }) => {
    const [cameras, setCameras] = useState([]);
    const [microphones, setMicrophones] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState('');
    const [selectedMicrophone, setSelectedMicrophone] = useState('');
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [error, setError] = useState(null);

    // Enumerate available devices on component mount
    useEffect(() => {
        async function initDevices() {
            try {
                // Request a dummy media stream to trigger the permissions dialog
                await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

                // Enumerate devices after permissions are granted
                const devices = await navigator.mediaDevices.enumerateDevices();
                setCameras(devices.filter((device) => device.kind === 'videoinput'));
                setMicrophones(devices.filter((device) => device.kind === 'audioinput'));
            } catch (error) {
                console.error('Error accessing media devices:', error);
                setError('Failed to access media devices. Please check permissions.');
            }
        }

        initDevices();
    }, []);

    // Function to stop all tracks in a media stream
    const stopMediaStream = (stream) => {
        if (stream) {
            // Stop all tracks
            stream.getTracks().forEach((track) => {
                track.stop();
            });

            // Explicitly set the video element source to null
            if (videoRef.current) {
                videoRef.current.srcObject = null;
            }
        }
    };


    const handleBack = () => {
        setMediaStream((prevStream) => {
            stopMediaStream(prevStream); // Stop all tracks
            return null; // Clear the stream
        });
        onBack(); // Call the parent-provided back function
    };

    // Update the media stream whenever selected devices change
    const updateMediaStream = async () => {
        try {
            setError(null); // Clear previous errors

            // Stop the existing media stream
            setMediaStream((prevStream) => {
                stopMediaStream(prevStream);
                return null;
            });

            // Create constraints for the selected devices
            const constraints = {
                video: selectedCamera ? { deviceId: { exact: selectedCamera } } : false,
                audio: selectedMicrophone ? { deviceId: { exact: selectedMicrophone } } : false,
            };

            // Request a new media stream
            const newStream = await navigator.mediaDevices.getUserMedia(constraints);

            // Update the video element with the new stream
            if (videoRef.current) {
                videoRef.current.srcObject = newStream;
            }

            // Pass the new stream to the parent
            setMediaStream(newStream);

            // If a microphone is selected, visualize the audio
            if (selectedMicrophone) {
                visualizeAudio(newStream);
            }
        } catch (err) {
            console.error('Error updating media stream:', err);
            setError('Failed to update media stream. Please check your device selection.');
        }
    };

    // Visualize audio amplitude for the selected microphone
    const visualizeAudio = (stream) => {
        const audioTracks = stream.getAudioTracks();
        if (!audioTracks.length) return;

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const source = audioContext.createMediaStreamSource(stream);
        const analyzer = audioContext.createAnalyser();
        analyzer.fftSize = 256;

        source.connect(analyzer);

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const bufferLength = analyzer.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const draw = () => {
            analyzer.getByteFrequencyData(dataArray);

            ctx.clearRect(0, 0, canvas.width, canvas.height);

            const numBars = 20;
            const barWidth = canvas.width / numBars;
            const maxAmplitude = Math.max(...dataArray);
            const activeBars = Math.ceil((maxAmplitude / 255) * numBars);

            for (let i = 0; i < numBars; i++) {
                const isActive = i < activeBars;
                ctx.fillStyle = isActive ? 'turquoise' : '#ccc';
                ctx.fillRect(i * barWidth, canvas.height / 2, barWidth - 2, -canvas.height / 4);
            }

            requestAnimationFrame(draw);
        };

        draw();
    };

    // Trigger update when selected devices change
    useEffect(() => {
        if (selectedCamera || selectedMicrophone) {
            updateMediaStream();
        }
    }, [selectedCamera, selectedMicrophone]);

    return (
        <div className="form-container">
            <h2>Aufnahmeeinstellung</h2>

            {error && <p className="error">{error}</p>}

            <div className="form-group">
                <label>Kamera:</label>
                <select
                    value={selectedCamera}
                    onChange={(e) => setSelectedCamera(e.target.value)}
                >
                    <option value="">Keine Kamera</option>
                    {cameras.map((camera) => (
                        <option key={camera.deviceId} value={camera.deviceId}>
                            {camera.label || `Kamera ${camera.deviceId}`}
                        </option>
                    ))}
                </select>
            </div>

            <div className="video-preview">
                {selectedCamera ? (
                    <video ref={videoRef} autoPlay muted playsInline style={{ width: '100%' }} />
                ) : (
                    <div>
                        <VideocamOff fontSize="large" color="disabled" />
                        <p>Ohne Kamera üben.</p>
                    </div>
                )}
            </div>

            <div className="form-group">
                <label>Mikrofon:</label>
                <select
                    value={selectedMicrophone}
                    onChange={(e) => setSelectedMicrophone(e.target.value)}
                >
                    <option value="">Kein Mikrofon</option>
                    {microphones.map((microphone) => (
                        <option key={microphone.deviceId} value={microphone.deviceId}>
                            {microphone.label || `Mikrofon ${microphone.deviceId}`}
                        </option>
                    ))}
                </select>
            </div>

            {selectedMicrophone ? (
                <div className="audio-visualization">
                    <canvas ref={canvasRef} width="300" height="100" />
                </div>
            ) : (
                <div>
                    <MicOffIcon fontSize="large" color="disabled" />
                    <p>Kein Mikrofon ausgewählt!</p>
                </div>
            )}

            <div className="button-group">
                <button type="button" onClick={handleBack} className="button">
                    Zurück
                </button>

                {/* Disable the button if no mic is selected */
                    !selectedMicrophone ? (<p> Microfon muss ausgewählt werden!</p>) :

                        /* Only enable button if connection state is 'connected' */
                        connectionStatus === 'connected' ? 
                            (/* Enable the button if a mic is selected */
                                <button
                                    type="button"
                                    onClick={onStart}
                                    className="button"
                                >
                                    Weiter
                                </button>):
                            (<p> Verbindung wird hergestellt...</p>)


                }
            </div>
        </div>
    );
};

export default DeviceSetup;
