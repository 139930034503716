// src/pages/Welcome.js
import React from 'react';
import '../styles/Welcome.css';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const Welcome = () => {
  const navigate = useNavigate();

  const navigateToTraining = () => {
    navigate('/training');
  };

  return (
    <div className="welcome-page">
      <h1 className="welcome-text">Willkommen!</h1>
      <div className="training-button" onClick={navigateToTraining}>
        <AiOutlinePlus size={40} className="plus-icon" />
        <span>Training</span>
      </div>
    </div>
  );
};

export default Welcome;
