import React, { createContext, useContext, useState, useEffect } from 'react';

import config from '../config/config';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  // Function to validate authentication via the backend
  const validateAuth = async () => {
    try {
      const response = await fetch(`${config.AUTH_URL}/validate`, {
        method: 'GET',
        credentials: 'include', // Include HttpOnly cookies in the request
      });

      if (response.ok) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);

      }
    } catch (error) {
      setIsAuthenticated(false);

    } finally {
      setLoading(false); // Stop loading once validation completes
    }
  };

  // Validate authentication on component mount
  useEffect(() => {
    validateAuth();
  }, []);

  // Login and logout methods
  const login = async (isAdmin) => {
    setIsAdmin(isAdmin);
    await validateAuth(); // Re-validate after login
  };

  const logout = async () => {
    try {
      await fetch(`${config.AUTH_URL}/logout`, {
        method: 'POST',
        credentials: 'include', // Include cookies in the request
      });
      setIsAuthenticated(false);
      setIsAdmin(false);
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, loading, isAdmin}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
