import React from 'react';
import Banner from './Banner';
import PresadaSidebar from './Sidebar';
import '../styles/Layout.css';

const Layout = ({ children }) => {
  return (
    <div className="layout-container">
      <Banner />
      <div className="main-layout">
        <PresadaSidebar />
        <main className="content">
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;
