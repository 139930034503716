import React, { useEffect, useRef, useState } from 'react';
import VideocamOff from '@mui/icons-material/VideocamOff';

const Recording = ({ formData, mediaStream, onStop, onEvaluate }) => {
    const videoRef = useRef(null);
    const [elapsedTime, setElapsedTime] = useState(0);

    useEffect(() => {
        if (mediaStream && videoRef.current) {
            videoRef.current.srcObject = mediaStream; // Attach video feed
        }
    }, [mediaStream]);

    useEffect(() => {
        // Start timer when component mounts
        const timer = setInterval(() => {
            setElapsedTime((prevTime) => prevTime + 1);
        }, 1000);

        // Cleanup timer when component unmounts
        return () => clearInterval(timer);
    }, []);

    // Format time in HH:MM:SS
    const formatTime = (timeInSeconds) => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = timeInSeconds % 60;

        return [
            hours.toString().padStart(2, '0'),
            minutes.toString().padStart(2, '0'),
            seconds.toString().padStart(2, '0'),
        ].join(':');
    };

    return (
        <div className="form-container">
            {/* Display the selected form image */}
            <div className="timer">
                <h2>{formatTime(elapsedTime)}</h2>
            </div>  
            <div className="recording-image">
                {(() => {
                    try {
                                switch (formData?.theme) {
                                    case 'audience_chamber':
                                        return (
                                            <img
                                                src={require('../../assets/themes/audience_chamber.svg').default}
                                                alt="audience_chamber"
                                            />
                                        );
                                    case 'billiard_room':
                                        return (
                                            <img
                                                src={require('../../assets/themes/billiard_room.svg').default}
                                                alt="billiard_room"
                                            />
                                        );
                                    case 'general':
                                        return (
                                            <img
                                                src={require('../../assets/themes/general.svg').default}
                                                alt="general"
                                            />
                                        );
                                    case 'guards_room':
                                        return (
                                            <img
                                                src={require('../../assets/themes/guards_room.svg').default}
                                                alt="guards_room"
                                            />
                                        );
                                    default:
                                        return (
                                            <img
                                                src={require('../../assets/themes/default.svg').default}
                                                alt="default"
                                            />
                                        );
                                }
                        } catch (error) {
                    console.error('Error loading image:', error);
                return (
                <img
                    src={require('../../assets/themes/default.svg').default}
                    alt="default"
                />
                );
                        }
                    })()}
            </div>


            <div className="video-feed">
                {mediaStream && mediaStream.getVideoTracks().length > 0 ? (
                    <video ref={videoRef} autoPlay muted playsInline style={{ width: '100%' }} />
                ) : (
                    <div>
                        <VideocamOff fontSize="large" color="disabled" />
                    </div>
                )}
            </div>

            <div className="button-group">
                <button type="button" onClick={onStop} className="button">
                    Abbruch
                </button>
                <button type="button" onClick={onEvaluate} className="button">
                    Zur Auswertung
                </button>
            </div>
        </div>
    );
};

export default Recording;