import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

import config from '../config/config';

const Settings = () => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        isAdmin: false,
    });
    const navigate = useNavigate();
    const [signUpState, setSignUpState] = useState('');

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload
        try {
            let body = {
                first_name: formData.firstName,
                last_name: formData.lastName,
                password: formData.password,
                is_admin: formData.isAdmin,
                email: formData.email,
            };
            const response = await fetch(`${config.API_URL}/sign_up`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
                credentials: 'include',
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Signup failed');
            }
            setSignUpState('Signup successful');
        } catch (error) {
            setSignUpState(error.message);
            console.error('Signup error:', error);
        }
    };

    return (
        <div>
            <h1>Signup</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>First Name:</label>
                    <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Last Name:</label>
                    <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Email:</label>
                    <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            name="isAdmin"
                            checked={formData.isAdmin}
                            onChange={handleChange}
                        />
                        Is Admin
                    </label>
                </div>
                <button type="submit">Sign Up</button>
                {signUpState && <p className="signUpState">{signUpState}</p>}
            </form>
        </div>
    );
};

export default Settings;
