import React from 'react';
import '../styles/Banner.css';

import logo from '../assets/logo_black.png';

const Banner = () => {
  return (
    <header className="banner">
      <img src={logo} alt="Presada Logo" className="logo" />
    </header>
  );
};

export default Banner;
