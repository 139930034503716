import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import PrivateRoute from './components/PrivateRoute';
import PrivateAdminRoute from './components/PrivateAdminRoute';
import Login from './pages/Login';
import Welcome from './pages/Welcome';
import Training from './pages/Training';
import Settings from './pages/Settings';

function App() {
  return (
    <Router
      future={{
        v7_relativeSplatPath: true,
        v7_startTransition: true,
      }}
    >
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />

        {/* Protected Routes */}
        <Route path="/welcome"
          element={
            <PrivateRoute>
              <Layout>
                <Welcome />
              </Layout>
            </PrivateRoute>
          }
        />
        <Route path="/training"
          element={
            <PrivateRoute>
              <Layout>
                <Training />
              </Layout>
            </PrivateRoute>
          }
        />

      <Route path="/settings"
          element={
            <PrivateAdminRoute>
              <Layout>
                <Settings />
              </Layout>
            </PrivateAdminRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;

