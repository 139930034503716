class WebRTCManager {
    constructor(config, onMessage) {
        this.peerConnection = null;
        this.dataChannel = null;
        this.iceComplete = false;
        this.config = config;
        this.onMessage = onMessage;
        this.connectionState = null;
        this.audioTransceiver = null;
        this.videoTransceiver = null;
    }

    initialize() {
        this.peerConnection.onicecandidate = (event) => {
            if (!event.candidate && !this.iceComplete) {
                this.iceComplete = true;
            }
        };

        // ICE gathering timeout
        setTimeout(() => {
            if (!this.iceComplete) {
                this.iceComplete = true;
            }
        }, 2000);
    }

    createDataChannel(label = 'message') {
        this.dataChannel = this.peerConnection.createDataChannel(label);
        this.dataChannel.onmessage = this.onMessage;
    }

    async createSDPOffer() {
        try {
            // Reset the PeerConnection
            if (this.peerConnection) {
                this.peerConnection.close(); // Close any existing connection
            }

            const configuration = { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] };
            this.peerConnection = new RTCPeerConnection(configuration); // Create a new PeerConnection

            // Create data channel
            this.createDataChannel();

            // Reserve media lines using addTransceiver
            this.audioTransceiver = this.peerConnection.addTransceiver('audio'); // Reserve audio m-line
            this.videoTransceiver = this.peerConnection.addTransceiver('video'); // Reserve video m-line

            // Create SDP offer
            const offer = await this.peerConnection.createOffer();
            await this.peerConnection.setLocalDescription(offer); // Set local description

            this.initialize();

            return true;
        } catch (error) {
            console.error('Error creating SDP offer:', error);
            return false;
        }
    }

    trackConnection = () => {
        return new Promise((resolve, reject) => {
            const connectionStateHandler = () => {
                if (this.peerConnection.connectionState === 'connected') {
                    resolve();
                } else if (this.peerConnection.connectionState === 'failed') {
                    reject(new Error('Connection failed: PeerConnection could not connect.'));
                }
            };
    
            // Handle current state in case it's already resolved
            if (this.peerConnection.connectionState === 'connected') {
                resolve();
            } else if (this.peerConnection.connectionState === 'failed') {
                reject(new Error('Connection failed: PeerConnection could not connect.'));
            }
    
            // Listen for future state changes
            this.peerConnection.onconnectionstatechange = connectionStateHandler;
        });
    };
    

    async sendSDPOffer(data) {
        const plannedDuration = parseInt(data.plannedTime, 10) * 60;

        const sdpOfferData = {
            sdp: this.peerConnection.localDescription.sdp,
            sdp_type: this.peerConnection.localDescription.type,
            iceComplete: this.iceComplete,
            language: data.language,
            theme: data.theme,
            audience: data.audience,
            planned_duration: plannedDuration,
        };

        const response = await fetch(`${this.config.API_URL}/sdp_offer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(sdpOfferData)
        });
        // Handle the response from the server
        if (response.ok) {
            const data = await response.json();
            // Handle the remote peer's answer (when they send it)
            if (data.sdp_answer_type === 'answer') {

                await this.setRemoteDescription(data.sdp_answer);
                return data.session_id;

            } else {
                console.error('Failed to receive answer');
                return null;
            }

        } else {
            console.error('Failed to send offer');
            return null;
        }
    }

    async setRemoteDescription(sdpAnswer) {
        const remoteDescription = new RTCSessionDescription({
            type: 'answer',
            sdp: sdpAnswer,
        });
        await this.peerConnection.setRemoteDescription(remoteDescription);
    }

    addTrack(track, stream) {
        this.peerConnection.addTrack(track, stream);
    }

    replaceTrack(kind, track) {
        let transceiver;
        if (kind === 'audio') {
            transceiver = this.audioTransceiver;
        } else if (kind === 'video') {
            transceiver = this.videoTransceiver;
        }

        if (transceiver && transceiver.sender) {
            transceiver.sender.replaceTrack(track);
        } else {
            console.warn(`No transceiver or sender found for ${kind}.`);
        }
    }

    sendMessage(msg) {
        if (this.dataChannel?.readyState === 'open') {
            this.dataChannel.send(msg);
        } else {
            console.warn('Data channel is not open yet.');
        }
    }

    close() {
        if (this.peerConnection) {
            this.peerConnection.close();
            this.peerConnection = null;
        }
    }
}

export default WebRTCManager;
