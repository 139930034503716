import { useForm, Controller } from 'react-hook-form';

import '../../styles/Training.css';
import '../../styles/training_subpages/TrainingSettings.css';

const TrainingSettings = ({ onExerciseNow, handleBack }) => {
    const { control, handleSubmit } = useForm({
        defaultValues: {
            language: 'de',
            theme: 'general',
            audience: 'mixed',
        },
    });

    const onSubmit = data => {
        onExerciseNow(data);
    };

    return (
        <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
            <h2>Trainingsoptionen</h2>
            <div className="form-group">
                <label className="label">Sprache</label>
                <Controller
                    control={control}
                    name="language"
                    render={({ field: { value, onChange } }) => (
                        <div className="option-group">
                            <div
                                className={`option ${value === 'de' ? 'selected' : ''}`}
                                onClick={() => onChange('de')}
                            >
                                Deutsch
                            </div>
                            <div
                                className={`option ${value === 'en' ? 'selected' : ''}`}
                                onClick={() => onChange('en')}
                            >
                                Englisch
                            </div>
                        </div>
                    )}
                />
            </div>

            <div className="form-group">
                <label className="label">Thema</label>
                <Controller
                    control={control}
                    name="theme"
                    render={({ field: { onChange, value } }) => (
                        <select
                            className="input"
                            value={value}
                            onChange={onChange}
                        >
                            <option value="general">Allgemein</option>
                            <option value="guards_room">Gardezimmer</option>
                            <option value="billiard_room">Billiardzimmer</option>
                            <option value="audience_chamber">Audienzzimmer</option>
                        </select>
                    )}
                />
            </div>

            <div className="form-group">
                <label className="label">Zielgruppe</label>
                <Controller
                    control={control}
                    name="audience"
                    render={({ field: { value, onChange } }) => (
                        <div className="option-group">
                            <div
                                className={`option ${value === 'mixed' ? 'selected' : ''}`}
                                onClick={() => onChange('mixed')}
                            >
                                Gemischte Sammelführung
                            </div>
                            <div
                                className={`option ${value === 'experts' ? 'selected' : ''}`}
                                onClick={() => onChange('experts')}
                            >
                                Experten
                            </div>
                            <div
                                className={`option ${value === 'simpleLanguage' ? 'selected' : ''}`}
                                onClick={() => onChange('simpleLanguage')}
                            >
                                Einfache Sprache
                            </div>
                        </div>
                    )}
                />
            </div>

            <div className="form-group">
                <label className="label">Geplante Zeit</label>
                <Controller
                    control={control}
                    name="plannedTime"
                    defaultValue='5'
                    render={({ field: { value, onChange } }) => (
                        <div className="slider-container">
                            <input
                                type="range"
                                min="1"
                                max="90"
                                step="1"
                                value={value || 1} // Default value if not set
                                onChange={(e) => onChange(parseInt(e.target.value, 10))}
                                className="slider"
                            />
                            <div className="slider-value">{String(value || 1).padStart(2, ' ')} Minuten</div>
                        </div>
                    )}
                />
            </div>


            <div className="button-group">
                <button type="button" onClick={handleBack} className="button">
                    Zurück
                </button>
                <button type="submit" className="button">
                    Weiter
                </button>
            </div>
        </form>
    );
};

export default TrainingSettings;