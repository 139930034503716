const ProgressWheel = () => {
    return (
        <div className="form-container">

                <div className="sandclock"></div>
            <p>Loading...</p>
        </div>
    );
};

export default ProgressWheel;
